import api from "@/base/utils/request";

//获取分类列表
export const categoryList = data => {
  return api({
    url: "/admin/cyc_circle/category/index",
    method: "post",
    data
  });
};
//分类排序
export const UpdateSort = data => {
  return api({
    url: "/admin/cyc_circle/category/updateSort",
    method: "post",
    data
  });
};
//分类显示
export const categoryUpdateShow = data => {
  return api({
    url: "/admin/cyc_circle/category/updateShow",
    method: "post",
    data
  });
};
//新增分类
export const saveCategory = data => {
  return api({
    url: "/admin/cyc_circle/category/save",
    method: "post",
    data
  });
};
//全部分类
export const allCategory = data => {
  return api({
    url: "/admin/cyc_circle/category/all",
    method: "post",
    data
  });
};
//合并分类
export const mergeCategory = data => {
  return api({
    url: "/admin/cyc_circle/category/mergeCategory",
    method: "post",
    data
  });
};
//删除分类
export const delCategory = data => {
  return api({
    url: "/admin/cyc_circle/category/del",
    method: "post",
    data
  });
};