<template>
  <div class="member-list">
    <el-table :data="RecodeList" class="thead-light" stripe style="width: 100%">
      <template v-for="(th, index) in thead">
        <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
          :min-width="th.minWidth || ''" :key="index">
          <template slot-scope="scope">
            <span>{{ scope.row[th.prop] | placeholder }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changePage" />
  </div>
</template>

<script>
import { logRecodeList } from "../../api/content-list";
import Pagination from "@/base/components/Default/Pagination";
export default {
  props: {
    id: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      thead: [
        { label: "ID", prop: "id", minWidth: 60 },
        { label: "操作人", prop: "nickname", minWidth: 100 },
        { label: "操作说明", prop: "content", minWidth: 250 },
        {
          label: "操作时间",
          prop: "create_time",
          minWidth: 150,
        },
      ],
      RecodeList: [],
      //数据请求对象
      requesData: {
        id: 0,
        page_size: 15, //每页多少条数据
        page: 1,
      },
      pageData: {
        total: 15,
        per_page: 15,
        current_page: 1,
        last_page: 1,
      },
      AllSourceList: [],
      selectArr: [], //勾选的数组id
    };
  },
  methods: {
    // 获取记录列表
    getRecodeList() {
      logRecodeList(this.requesData).then((res) => {
        const { data } = res;
        this.RecodeList = data.data;
        this.pageData = data;
        this.$emit("update:recodeNum", data.total);
      });
    },
    // 输入改变页数
    changePage(e) {
      this.requesData = { ...this.requesData, ...e };
      this.getRecodeList();
    },
  },
  created() {
    if (this.id) {
      this.requesData.id = this.id;
      this.getRecodeList();
    }
  },
  components: {
    Pagination,
  },
};
</script>

<style>
</style>