<template>
  <div>
  <el-table :data="tbody" class="thead-light" stripe style="width: 100%">
    <template v-for="(th, index) in thead">
      <el-table-column :prop="th.prop" :label="th.label" :min-width="th.minWidth || ''" :key="index">
        <template slot-scope="scope">
          <!-- 运营修改 -->
          <PopoverInputEdit v-if="th.prop === 'virtual_num' && [0,1].includes(scope.$index)"
            v-model="scope.row[th.prop]" input-type="number" :validator="validator"
            @confirm="valueChange(scope.$index)" />
          <!-- 普通 -->
          <span v-else>{{ scope.row[th.prop] | placeholder }}</span>

          <!-- 举报查看 -->
          <el-button v-if="index===1 && scope.$index===4 && scope.row[th.prop] > 0" type="text" @click="checkReportRecode(formData.id)">查看</el-button>
        </template>
      </el-table-column>
    </template>
    <!-- 显示数据 -->
    <el-table-column label="显示数据">
      <template slot-scope="scope">
        {{(typeof scope.row.virtual_num !== 'string')?scope.row.num + scope.row.virtual_num:scope.row.num}}
      </template>
    </el-table-column>

  </el-table>
    <!-- 举报记录弹窗 -->
    <ReportRecodeDialog v-model="showReportRecodeDialog" :requestData="ReportRecodeRequest"/>
  </div>
</template>

<script>
import PopoverInputEdit from "@/base/components/Popover/PopoverInputEdit";
import ReportRecodeDialog from "@/modules/common/components/ReportRecodeDialog";
import { UpdateVirtual } from "../../api/content-list";
export default {
  components: { PopoverInputEdit,ReportRecodeDialog },
  model: {
    prop: "formData",
    event: "DataChange",
  },
  props: {
    formData: {
      type: Object,
    },
  },
  data() {
    return {
      tbody: [
        {
          name: "浏览量",
          num: 0,
          virtual_num: 0,
        },
        {
          name: "评论量",
          num: 0,
          virtual_num: 0,
        },
        {
          name: "转发量",
          num: 0,
          virtual_num: 0,
        },
        {
          name: "收藏量",
          num: 0,
          virtual_num: 0,
        },
        {
          name: "举报量",
          num: 0,
          virtual_num: 0,
        },
      ],
      thead: [
        { label: "", prop: "name", minWidth: 100 },
        { label: "用户数据", prop: "num", minWidth: 100 },
        { label: "运营修改", prop: "virtual_num", minWidth: 100 },
      ],
      validator: {
        type: "integer",
        min: 0,
        message: "最小不能低于0的整数",
        trigger: "blur",
      },
      test: 0,
      showReportRecodeDialog: false, //是否显示举报记录弹窗
      ReportRecodeRequest: {
        page: 1,
        page_size: 15,
        data_type: "cyc_circle", // 举报数据类型
        data_table: "cyc_circle_content", // 举报数据表
        data_id: "", // 举报内容id
      },
    };
  },
  methods: {
    // 查看举报记录
    checkReportRecode(id) {
      this.ReportRecodeRequest.data_id = id;
      this.showReportRecodeDialog = true;
    },
    // 列表数据更新
    valueChange(i) {
      if (i === 0) {
        this.Data.virtual_view_num = this.tbody[0].virtual_num;
      }
      if (i === 1) {
        this.Data.virtual_comment_num = this.tbody[1].virtual_num;
      }
    },
  },
  watch: {
    Data: {
      deep: true,
      handler(val) {
        this.tbody[0].num = val.view_num;
        this.tbody[0].virtual_num = val.virtual_view_num;
        this.tbody[1].num = val.comments_count;
        this.tbody[1].virtual_num = val.virtual_comment_num;
        this.tbody[2].num = val.forward_num;
        this.tbody[2].virtual_num = "--";
        this.tbody[3].num = val.collect_count;
        this.tbody[3].virtual_num = "--";
        this.tbody[4].num = val.complaints_count;
        this.tbody[4].virtual_num = "--";
      },
    },
  },
  computed: {
    Data: {
      get() {
        return this.formData;
      },
      set(val) {
        this.$emit("DataChange", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.check-post {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.el-input {
  ::v-deep.el-input-group__prepend {
    background-color: #fff;
  }
}
</style>
