import api from "@/base/utils/request";
import fetchFile from "@/base/utils/download";

// 获取资源圈列表
export const CircleList = data => {
  return api({
    url: "/admin/cyc_circle/content/index",
    method: "post",
    data
  });
};

// 获取分类选择列表
export const CategoryList = data => {
  return api({
    url: "/admin/cyc_circle/category/all",
    method: "post",
    data
  });
};

// 删除移入回收站
export const softDelete = data => {
  return api({
    url: "/admin/cyc_circle/content/softDelete",
    method: "post",
    data
  });
};

// 回收站永久删除
export const Delete = data => {
  return api({
    url: "/admin/cyc_circle/content/del",
    method: "post",
    data
  });
};

// 回收站还原数据
export const putBack = data => {
  return api({
    url: "/admin/cyc_circle/content/putBack",
    method: "post",
    data
  });
};

// 更新排序
export const updateSort = data => {
  return api({
    url: "/admin/cyc_circle/content/updateSort",
    method: "post",
    data
  });
};

// 通过审核
export const Checked = data => {
  return api({
    url: "/admin/cyc_circle/content/check",
    method: "post",
    data
  });
};

// 不通过审核
export const NoCheck = data => {
  return api({
    url: "/admin/cyc_circle/content/nocheck",
    method: "post",
    data
  });
};

// 拉黑
export const JoinBlack = data => {
  return api({
    url: "/admin/cyc_circle/content/JoinBlack",
    method: "post",
    data
  });
};

// 移出黑名单
export const RemoveBlack = data => {
  return api({
    url: "/admin/cyc_circle/content/RemoveBlack",
    method: "post",
    data
  });
};

//黑名单移出记录
export const UserBlackRecode = data => {
  return api({
    url: "/admin/cyc_circle/content/userBlackDetail",
    method: "post",
    data
  });
};

// 修改运营数据
export const UpdateVirtual = data => {
  return api({
    url: "/admin/cyc_circle/content/updateVirtual",
    method: "post",
    data
  });
};

//是否设为付费
export const PayConfig = data => {
  return api({
    url: "/admin/cyc_circle/content/pay",
    method: "post",
    data
  });
};

//设置置顶
export const contentUpdateTop = data => {
  return api({
    url: "/admin/cyc_circle/content/updateTop",
    method: "post",
    data
  });
};
//修改置顶排序
export const contentUpdateSort = data => {
  return api({
    url: "/admin/cyc_circle/content/updateSort",
    method: "post",
    data
  });
};

// 获取被举报记录
export const ReportList = data => {
  return api({
    url: "/admin/cyc_circle/content/reportLog",
    method: "post",
    data
  });
};

//查看操作日志
export const logRecodeList = data => {
  return api({
    url: "/admin/cyc_circle/content/log",
    method: "post",
    data
  });
};

// 查看评论内容
export const commentList = data => {
  return api({
    url: "/admin/cyc_circle/comment/ContentAll",
    method: "post",
    data
  });
};

// 删除评论
export const commentDelete = data => {
  return api({
    url: '/admin/cyc_circle/comment/softDelete',
    method: 'post',
    data
  });
};

// 资源列表导出
export const documentContentList = data => {
  return fetchFile({
    url: "/admin/cyc_circle/content/export",
    method: "post",
    data,
    download: true
  });
};