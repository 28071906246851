<template>
  <el-dialog title="所有评论" :visible.sync="openDialog" width="900px" :show-close="false" class="dialog-vertical"
    @close="cancel">
    <div class="head-filter">
      <el-input size="small" v-model="requesData.user_keyword" placeholder="请输入评论者名称" @change="handleFilter">
        <i class="el-icon-search el-input__icon" slot="suffix"></i>
      </el-input>
      <el-input size="small" v-model="requesData.keyword" placeholder="请输入评论内容" @change="handleFilter">
        <i class="el-icon-search el-input__icon" slot="suffix"></i>
      </el-input>
    </div>
    <div class="member-list">
      <el-table :data="commentList" class="thead-light" stripe style="width: 100%" :height="300"
        v-loading="loading" @sort-change="sortChange">
        <!-- 操作 -->
        <el-table-column label="操作" :width="60" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="deleteRow(scope.row)">删除</el-button>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
            :min-width="th.minWidth || ''" :sortable="th.sortable" :key="index">
            <template slot-scope="scope">
              <!-- 头像 -->
              <el-image class="tabel-avatar" v-if="th.prop === 'avatar'"
                :src="fixImageUrl(scope.row[th.prop])" fit="cover"></el-image>
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <div class="pagination" v-if="pageData.last_page >1">
        <el-pagination background layout="prev, pager, next" :page-size="pageData.per_page"
          :total="pageData.total" :current-page="Number(pageData.current_page)" @current-change="pageChange">
        </el-pagination>
        <el-input class="number-input" type="number" min="1" :max="pageData.last_page" auto-complete="off"
          v-model="requesData.page"></el-input>
        <el-button class="page-jump" type="primary" @click="changePage">GO</el-button>
      </div>
    </div>
    <span slot="footer">
      <el-button @click="openDialog = false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { commentList, commentDelete } from "../../api/content-list";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [Number, String],
    },
  },
  data() {
    return {
      loading: false, //加载
      thead: [
        { label: "头像", prop: "avatar", minWidth: 60 },
        { label: "会员名称", prop: "nickname", minWidth: 100 },
        { label: "架构/会内职务", prop: "title_name", minWidth: 150 },
        { label: "评论内容", prop: "content", minWidth: 150 },
        { label: "点赞数", prop: "likes_count", minWidth: 80 },
        { label: "回复数", prop: "replies_count", minWidth: 80 },
        { label: "举报数", prop: "report", minWidth: 80 },
        { label: "上级评论内容", prop: "pid_content", minWidth: 150 },
        {
          label: "评论时间",
          prop: "create_time",
          minWidth: 150,
          sortable: true,
        },
      ],
      commentList: [], // 评论列表
      //数据请求对象
      requesData: {
        id: 0,
        page_size: 15, //每页多少条数据
        page: 1,
        keyword: "",
        user_keyword: "",
        is_desc: -1,
        order_by: "create_time",
      },
      pageData: {
        total: 15,
        per_page: 15,
        current_page: 1,
        last_page: 1,
      },
    };
  },
  methods: {
    // 获取评论列表
    getCommentList() {
      this.loading = true;
      commentList(this.requesData)
        .then((res) => {
          const { data } = res;
          this.commentList = data.data;
          this.pageData = data;
          this.loading = false;
        })
        .catch((err) => {});
    },
    // 序号计算
    indexMethod(index) {
      return index + 1;
    },
    //表格排序
    sortChange(column) {
      this.requesData.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.requesData.order_by = column.prop;
      this.getCommentList();
    },
    // 删除
    deleteRow(row) {
      commentDelete({ id: row.id })
        .then((res) => {
          this.$message.success(res.msg);
          this.getCommentList();
        })
        .catch((err) => {});
    },
    // 筛选操作
    handleFilter(e, key) {
      this.requesData.page = 1;
      this.getCommentList();
    },
    // 点击改变页数
    pageChange(e) {
      this.requesData.page = e;
      this.getCommentList();
    },
    // 输入改变页数
    changePage() {
      if (
        Number(this.requesData.page) > 0 &&
        Number(this.requesData.page) <= Number(this.pageData.last_page)
      ) {
        this.getCommentList();
      } else {
        this.requesData.page = this.pageData.current_page;
      }
    },
    // 取消
    cancel() {
      this.openDialog = false;
    },
  },
  created() {
    this.requesData.id = this.id;
    this.getCommentList();
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.head-filter {
  width: 60%;
  display: flex;
  position: absolute;
  top: 16px;
  right: 20px;

  .el-select,
  .el-cascader,
  .el-input {
    flex: 1;
    margin-right: 10px;
  }
}

.pagination {
  display: flex;
  height: 30;
  margin-top: 20px;
  align-items: center;
  .number-input {
    max-width: 60px;
    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      appearance: none;
    }
    ::v-deep.el-input__inner {
      height: 28px;
      border: 1px solid #dcdfe6;
      padding: 0 10px;
    }
  }
  .page-jump {
    height: 28px;
    line-height: 0;
    padding: 10px;
    margin-left: 10px;
  }
}
</style>
