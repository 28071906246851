<template>
  <el-dialog title="付费人数" :visible.sync="openDialog" width="900px" :show-close="false" class="dialog-vertical"
    @close="cancel">
    <div class="head-filter">
      <el-select size="small" v-model="requesData.position" placeholder="请选择职务" @change="handleFilter">
        <el-option v-for=" item in positionOptions" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
      <el-input size="small" v-model="requesData.keyword" placeholder="请输入会员名称" @change="handleFilter">
        <i class="el-icon-search el-input__icon" slot="suffix"></i>
      </el-input>
    </div>
    <div class="member-list">
      <el-table :data="paymentList" class="thead-light" stripe style="width: 100%" :height="300"
        v-loading="loading">
        <template v-for="(th, index) in thead">
          <el-table-column :prop="th.prop" :label="th.label" :min-width="th.minWidth || ''" :key="index">
            <template slot-scope="scope">
              <!-- 头像 -->
              <el-image class="tabel-avatar" v-if="th.prop === 'avatar'"
                :src="fixImageUrl(scope.row[th.prop])" fit="cover"></el-image>
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <div class="pagination" v-if="pageData.last_page >1">
        <el-pagination background layout="prev, pager, next" :page-size="pageData.per_page"
          :total="pageData.total" :current-page="Number(pageData.current_page)" @current-change="pageChange">
        </el-pagination>
        <el-input class="number-input" type="number" min="1" :max="pageData.last_page" auto-complete="off"
          v-model="requesData.page"></el-input>
        <el-button class="page-jump" type="primary" @click="changePage">GO</el-button>
      </div>
    </div>
    <span slot="footer">
      <el-button @click="openDialog = false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { paymentList, positionList } from "../../api/content-detail";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [Number, String],
    },
  },
  data() {
    return {
      loading: false, //加载
      thead: [
        { label: "头像", prop: "avatar", minWidth: 60 },
        { label: "会员名称", prop: "nickname", minWidth: 100 },
        { label: "职务", prop: "title_name", minWidth: 150 },
      ],
      paymentList: [], // 评论列表
      positionOptions: [],
      //数据请求对象
      requesData: {
        id: 0,
        page_size: 15, //每页多少条数据
        page: 1,
        keyword: "",
        position: "",
      },
      pageData: {
        total: 15,
        per_page: 15,
        current_page: 1,
        last_page: 1,
      },
    };
  },
  methods: {
    // 获取付费列表
    getPaymentList() {
      this.loading = true;
      const data = { ...this.requesData };
      data.position = data.position === "" ? -1 : data.position;
      paymentList(data)
        .then((res) => {
          const { data } = res;
          this.paymentList = data.data;
          this.pageData = data;
          this.getAllPositionList();
          this.loading = false;
        })
        .catch((err) => {});
    },
    // 获取所有职务
    getAllPositionList() {
      positionList({ add_none: 1 })
        .then((res) => {
          this.positionOptions = res.data;
        })
        .catch((err) => {});
    },
    // 筛选操作
    handleFilter(e, key) {
      this.requesData.page = 1;
      this.getPaymentList();
    },
    // 点击改变页数
    pageChange(e) {
      this.requesData.page = e;
      this.getPaymentList();
    },
    // 输入改变页数
    changePage() {
      if (
        Number(this.requesData.page) > 0 &&
        Number(this.requesData.page) <= Number(this.pageData.last_page)
      ) {
        this.getPaymentList();
      } else {
        this.requesData.page = this.pageData.current_page;
      }
    },
    // 取消
    cancel() {
      this.openDialog = false;
    },
  },
  created() {
    this.requesData.id = this.id;
    this.getPaymentList();
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.head-filter {
  width: 60%;
  display: flex;
  position: absolute;
  top: 16px;
  right: 20px;

  .el-select,
  .el-cascader,
  .el-input {
    flex: 1;
    margin-right: 10px;
  }
}

.pagination {
  display: flex;
  height: 30;
  margin-top: 20px;
  align-items: center;
  .number-input {
    max-width: 60px;
    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      appearance: none;
    }
    ::v-deep.el-input__inner {
      height: 28px;
      border: 1px solid #dcdfe6;
      padding: 0 10px;
    }
  }
  .page-jump {
    height: 28px;
    line-height: 0;
    padding: 10px;
    margin-left: 10px;
  }
}
</style>
