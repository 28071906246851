import api from "@/base/utils/request";

//获取内容详情
export const contentDetail = data => {
  return api({
    url: "/admin/cyc_circle/content/detail",
    method: "post",
    data
  });
};
//保存详情修改
export const contentSave = data => {
  return api({
    url: "/admin/cyc_circle/content/save",
    method: "post",
    data
  });
};
// 获取分类列表
export const CategoryList = data => {
  return api({
    url: "/admin/cyc_circle/category/all",
    method: "post",
    data
  });
};
//查看操作日志
export const logRecodeList = data => {
  return api({
    url: "/admin/cyc_circle/content/log",
    method: "post",
    data
  });
};

//查看付费记录
export const paymentList = data => {
  return api({
    url: "/admin/cyc_circle/content/PayPeople",
    method: "post",
    data
  });
};

// 获取所有职务
export const positionList = data => {
  return api({
    url: "/admin/cyc/position/all",
    method: "post",
    data
  });
};